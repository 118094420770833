







































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ConfigurableVariant } from '~/composables';
import {
  ProductWithCommonProductCardProps
} from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import { getAttributeValue, hasMoreVariants } from '~/bbrTheme/modules/catalog/getters/productGetters';
import GridDefault from '~/bbrTheme/modules/catalog/category/components/views/grid/family/Default.vue';
import Maturity from '~/bbrTheme/components/atoms/Maturity.vue';

export default defineComponent({
  name: 'GridWines',
  components: {
    Maturity,
  },
  mixins: [GridDefault],
  props: {
    variant: {
      type: [Object, null] as PropType<ConfigurableVariant | null>,
      default: null,
    },
    product: {
      type: Object as PropType<ProductWithCommonProductCardProps>,
      required: true,
    },
  },
  setup(props) {
    const preparedProduct = props.variant?.product || props.product;

    const regionLabel = getAttributeValue(preparedProduct, 'region');
    const maturity = getAttributeValue(preparedProduct, 'maturity');
    const colour = getAttributeValue(preparedProduct, 'colour');

    return {
      maturity,
      colour,
      preparedProduct,
      regionLabel,
      hasMoreVariants,
    };
  },
});
